export const menuItems = [
    {
        name: "partner",
        label: "Untuk bisnis",
    },
    {
        name: "faq",
        label: "Bantuan",
    },
];
