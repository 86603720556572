import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ListGroup, Collapse } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";
import { menuItems } from "../../components/Header/menuItems";

const NestedMenuContainer = styled.div`
    a.mainCTA{
      color: #fff !important;
      text-transform: none !important;
    }
    a {
        color: #121212 !important;
        transition: all 0.3s ease-out;
        font-weight: 700;
        text-transform: lowercase;

        &:hover,
        &:active {
            color: #121212;
            text-decoration: none;
        }
    }

    .list-group-item {
        font-weight: 700;
        text-transform: lowercase;
        color: #121212;
        &:hover,
        &:active,
        &.active {
            color: #121212;
            text-decoration: none;
            background-color: transparent;
            border-bottom: 1px solid rgba(22, 28, 45, 0.125);
        }

        & + .collapse:not(.show) {
            .list-group-item {
                border: none !important;
                border-width: 0 !important;
            }
        }
        & + .collapse.show {
            .list-group-item {
                &:first-child {
                    border-top: none !important;
                }
            }
        }
    }
    .collapse + .list-group-item {
        border-top-width: 0;
    }
    /* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`;

const MenuItem = ({
    label,
    isExternal = false,
    name,
    items,
    depthStep = 20,
    depth = 0,
    ...rest
}) => {
    const [open, setOpen] = useState(false);
    const hasSubItems = Array.isArray(items);

    const gContext = useContext(GlobalContext);

    return (
        <>
            {hasSubItems ? (
                <ListGroup.Item
                    {...rest}
                    css={`
                        padding-left: ${depth * depthStep}px !important;
                        padding-right: 0 !important;
                        cursor: pointer;
                    `}
                    onClick={() => setOpen(!open)}
                    className={`d-flex align-items-center justify-content-between ${
                        open ? "active" : ""
                    }`}
                >
                    <span>{label}</span>
                    <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
                </ListGroup.Item>
            ) : (
                <ListGroup.Item
                    {...rest}
                    css={`
                        padding-left: ${depth * depthStep}px !important;
                        padding-right: 0 !important;
                    `}
                >
                    {isExternal ? (
                        <a
                            href={`${name}`}
                            onClick={() => {
                                if (gContext.visibleOffCanvas) {
                                    gContext.toggleOffCanvas();
                                }
                            }}
                        >
                            {label}
                        </a>
                    ) : (
                        <Link
                            to={`/${name}`}
                            onClick={() => {
                                if (gContext.visibleOffCanvas) {
                                    gContext.toggleOffCanvas();
                                }
                            }}
                        >
                            {label}
                        </Link>
                    )}
                </ListGroup.Item>
            )}

            {hasSubItems ? (
                <Collapse in={open}>
                    <ListGroup>
                        {items.map((subItem) => (
                            <MenuItem
                                key={subItem.name}
                                depth={depth + 1}
                                depthStep={depthStep}
                                {...subItem}
                            />
                        ))}
                    </ListGroup>
                </Collapse>
            ) : null}
        </>
    );
};

const NestedMenu = () => {
    return (
        <NestedMenuContainer>
            <ListGroup variant='flush'>
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={`${menuItem.name}${index}`}
                        depthStep={20}
                        depth={0}
                        {...menuItem}
                    />
                ))}
                <div style={{ width: "100%" }}>
                    <a
                        href='https://app.getkashout.co/partners'
                        target='_blank'
                        className='mainCTA btn btn-sm btn-dodger-blue-2 rounded-5 px-7 mt-8'
                        style={{ width: "100%", color: "#ffffff !important" }}
                    >
                        Daftar Sekarang
                    </a>
                </div>
            </ListGroup>
        </NestedMenuContainer>
    );
};

export default NestedMenu;
